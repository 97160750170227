const app = {
  init: () => {
    console.log("Initialisation de l'app");
    resellersList.init();
    searchInputs.init();
    mapComponent.init();
  },
};

document.addEventListener("DOMContentLoaded", app.init);
