// Import d'AOS
import AOS from 'aos'
AOS.init()

// Import all plugins
import * as bootstrap from 'bootstrap'


// Import custom CSS
import './../scss/main.scss';

import './app.js';


// Activation of tooltip
var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})